<template>
<div class="content ">
  <div class="col-md-12 p-0 all-container">
    <div class="row p-0 m-0 d-flex align-items-baseline">
      <div class="col-md-11 p-0 m-0 plan-container">
        <VueDragResize v-for="(booth,index) in booths" :key="index" class="store-container" :isActive="true" :x="booth.x" :y="booth.y" :w="booth.width" :h="booth.height" :isDraggable="false" :isResizable="false">
          <div class="store" @click="goToProductModel()">
            <div class="title">{{booth.title}}</div>
            <img :src="'https://image.freepik.com/free-psd/top-view-interior-living-room-with-smart-tv_38322-753.jpg'" class="store-image">
            <i class="fas fa-map-marker location-icon">
              <div class="users-count">{{booth.count}}</div>
            </i>
          </div>
        </VueDragResize>
      </div>
      <div class="col-md-1 p-0 m-0 legend d-flex justify-content-end float-right">
        <div class="d-flex align-items-center">
          <div><i class="fas fa-map-marker legend-icon yellow-text"></i></div>
          <span>Number of users in booth</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import VueDragResize from 'vue-drag-resize';

export default {
  name: 'Plan',
  components: {
    VueDragResize
  },
  data() {
    return {
      booths: [{
        x: 0,
        y: 0,
        width: 120,
        height: 60,
        count: 5
      },
      {
        x: 0,
        y: 80,
        width: 120,
        height: 110,
        count: 10
      },
      {
        x: 160,
        y: 0,
        width: 300,
        height: 190,
        count: 20
      },
      {
        x: 500,
        y: 0,
        width: 400,
        height: 160,
        count: 15
      },
      {
        x: 930,
        y: 0,
        width: 100,
        height: 100,
        count: 3
      },
      {
        x: 0,
        y: 230,
        width: 100,
        height: 240,
        count: 3
      },
      {
        x: 160,
        y: 230,
        width: 150,
        height: 240,
        count: 5
      },
      {
        x: 330,
        y: 230,
        width: 150,
        height: 140,
        count: 3
      },
      {
        x: 330,
        y: 390,
        width: 150,
        height: 80,
        count: 3
      },
      {
        x: 520,
        y: 230,
        width: 250,
        height: 240,
        count: 15
      },
      {
        x: 800,
        y: 230,
        width: 250,
        height: 240,
        count: 15
      }
      ],
      usersCount: [],
      exhibition: {}
    };
  },
  mounted(){
  },
  methods: {


    goToProductModel() {
      // this.$router.push('/start-card');
      this.$router.push({ path: 'krpano/2114ebb0-84b0-11ea-bbc4-0242ac130002'});
    },
    gotoBooth() {
      this.$router.push({ path: 'krpano/2114ebb0-84b0-11ea-bbc4-0242ac130002'});
    },
    getBooths(){
      this.$http.get('/booths', {
        params: {
          exhibition: 0
        }
      })
        .then(resp => {
          console.log(resp.data);
        }).catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
  body, html {
    height: 100%;
  }

  .content {
    margin: 0px;
    min-height: calc(100vh - 124px);;
    padding-left: 0px !important;
    padding-right: 0px !important;
    position: relative;
  }

  .location-icon {
    top: -30px;
    right: 10px;
    font-size: 40px;
    overflow: visible !important;
    position: absolute;
    color: #F4F044;
  }

  .store {
    width: 100%;
    height: 100%;
    border: 2px solid #FFFF66;
    position: relative;
  }

  .store:hover {
    cursor: pointer;
  }

  .title {
    position: absolute;
    left: 10px;
    top: 5px;
    font-size: 20px;
  }

  .users-count {
    position: absolute;
    display: inline;
    top: 20%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: auto;
    font-size: 12px;
    font-weight: 400;
    color: black;
    letter-spacing: 0.39px;
  }

  .store-image {
    height: 100%;
    width: 100%;
  }

  .plan-container {
    min-height: calc(100vh - 124px);
    overflow: auto;
    scrollbar-color: #F4F044  #695B08;
    scrollbar-width: thin;
  }

  .store-container {
    position: absolute;
    margin-top: 30px;
  }

  .legend {
    color: black;
    bottom: 25px;
  }

  .legend-icon {
    font-size: 30px;
    margin-top: 5px;
  }

  .legend span {
    font-size: 10px;
    font-weight: 500;
    margin-left: 20px;
  }

  .all-container {
    width: 90%;
    margin-bottom: 7vh;
    margin-left: 7%;
    margin-right: 3%;
  }

  @media (max-width: 480px) {
    .plan-container {
      margin: 15px 0%;
    }
  }
</style>
